import { lazyWithRetries } from 'utils/lazyWithRetries';

import './scss/index.min.css';

import Bowser from 'bowser';

import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import 'core-js/features/promise';
import './polyfill';

import 'config/i18n';

import { Suspense } from 'react';
import { isIE, isSafari } from 'utils';

// import * as Sentry from '@sentry/react';

const PolyfillLoader = lazyWithRetries(() => import('./PolyfillLoader'));
const App = lazyWithRetries(() => import('./App'));

const browser = Bowser.getParser(window.navigator.userAgent);
const isModernBrowser = browser.satisfies({
  chrome: '>65',
  edge: '>80',
  firefox: '>69',
  opera: '>70',
});

const isFirefox = browser.satisfies({
  firefox: '>69',
});

const isWebkitBrowser = browser.satisfies({
  chrome: '>65',
  safari: '>10',
});

if (isIE()) {
  document.body.classList.add('is-ie');
  import('./scss/ie-fixes.min.css');
}

if (isSafari) {
  document.body.classList.add('is-safari');
}

if (isWebkitBrowser) {
  document.body.classList.add('no-webkit');
}

// if (process.env.NODE_ENV === 'production') {
//   Sentry.init({
//     dsn: 'https://13d478e1d77e645c866d054eb160a5a5@o4506638154006528.ingest.sentry.io/4506638160691200',
//     integrations: [
//       new Sentry.BrowserTracing({
//         // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
//         tracePropagationTargets: [
//           'localhost',
//           /^https:\/\/mangelberger\.com\/api/,
//         ],
//       }),
//       new Sentry.replayIntegration({
//         maskAllText: false,
//         blockAllMedia: false,
//       }),
//     ],
//     // Performance Monitoring
//     tracesSampleRate: 0.5, //  Capture 100% of the transactions
//     // Session Replay
//     replaysSessionSampleRate: 0.025, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//     replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
//   });
// }

ReactDOM.render(
  <Suspense fallback={null}>
    {/* if not normaly working browser do the polyfills */}
    {!isModernBrowser ? (
      <PolyfillLoader>
        <Suspense fallback={null}>
          <App />
        </Suspense>
      </PolyfillLoader>
    ) : (
      <App />
    )}
  </Suspense>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorkerRegistration.unregister();
// if (isFirefox) {
// } else {
//   serviceWorkerRegistration.register();
// }
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
